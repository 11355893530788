<template>
    <div class="RoleEdit">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" :model="role" label-width="80px" :rules="rules" size="small">
                <el-form-item label="角色名称" prop="name">
                    <el-input v-model="role.name" />
                </el-form-item>
                <el-form-item label="角色类型">
                    <div>{{ form.type ? '业务型角色' : '管理型角色' }}</div>
                </el-form-item>
                <el-form-item label="角色范围" prop="type">
                    <el-radio-group v-model.number="role.openType">
                        <el-radio :label="1">私有</el-radio>
                        <el-radio :label="0">公开</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="归属类型">
                    <div>{{ form.deptType | deptType }}</div>
                </el-form-item>
                <el-form-item label="权重" prop="weight">
                    <el-input v-model.number="role.weight" />
                </el-form-item>
                <ef-remark v-model="role.remark" />
                <el-form-item>
                    <el-button type="primary" @click="handleSave" size="small">保存</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from '../../../../js/UrlUtils';
import EfRemark from 'components/EfRemark';

export default {
    name: 'RoleEdit',
    components: { EfRemark },
    props: ['form'],
    data() {
        return {
            role: {},
            url: '/system/role/patch_c/',
            rules: {
                name: [{ required: true, max: 200, message: '请输入名称', trigger: 'blur' }],
                remark: [{ required: true, max: 200, message: '请输入备注,长度不超过200', trigger: 'blur' }],
                weight: [
                    {
                        required: true,
                        min: 30,
                        type: 'integer',
                        message: '请输入大于等于30的正整数',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    mounted() {
        this.role = {
            name: this.form.name,
            weight: this.form.weight,
            remark: this.form.remark,
            deptType: this.form.deptType,
            openType: this.form.openType,
            type: this.form.type,
        };
    },
    methods: {
        handleSave() {
            const _this = this;
            this.$refs.form.validate((valid) => {
                if (!valid) return;
                UrlUtils.PatchRemote(this, this.url + this.form.code, this.role, null, (data) => {
                    _this.$message.success('保存成功');
                    this.goBackAndReload();
                });
            });
        },
    },
};
</script>
<style scoped>
.el-input {
    width: auto;
}
.el-textarea {
    width: auto;
}
</style>
